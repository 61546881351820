// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getAllGroups } from "src/reducers";
import { MultiSelect } from "src/components/Unifize";

import type { Group as UnifizeGroup } from "src/types";

type Props = {
  onChange: Function,
  value: Array<number>
};

const Group = ({ onChange, value }: Props) => {
  const groups: Array<UnifizeGroup> = useSelector(({ app }) =>
    getAllGroups(app)
  );

  return (
    <MultiSelect
      label="Value"
      labelWeight="bold"
      placeholder="Search"
      data={groups}
      selected={value}
      showIcon
      keys={["id", "name", "photoUrl"]}
      onChange={onChange}
      showSelectAll
    />
  );
};

export default Group;
